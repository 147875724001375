import yaml_mixin_handler from "@shared/mixins/yaml_mixin_handler";
import handlers from "@shared/mixins/handlers";

export default {
    mixins: [yaml_mixin_handler, handlers],
    methods: {
        getConditionsBlockResult(conditionsBlock) {
            if (!conditionsBlock || !conditionsBlock.conditions) {
                return true;
            }

            const conditionsResults = conditionsBlock.conditions.map(condition => {
                if ('conditionsBlock' in condition) {
                    // Recursive call for nested conditions
                    return this.getConditionsBlockResult(condition.conditionsBlock);
                }

                if ('operator' in condition) {
                    return this.evaluateCondition(condition);
                } else {
                    return this.resolveValue(condition.handler) == condition.value;
                }
            });

            const operator = conditionsBlock.operator || 'and';
            return operator === 'or'
                ? conditionsResults.includes(true)
                : !conditionsResults.includes(false);
        },

        evaluateCondition(condition) {
            const value = this.resolveValue(condition.handler);
            switch (condition.operator) {
                case '>': return value > condition.value;
                case '<': return value < condition.value;
                case '>=': return value >= condition.value;
                case '<=': return value <= condition.value;
                case '!=': return value != condition.value;
                case 'NULL': return value === null || value === undefined;
                case 'NOT NULL': return value !== null && value !== undefined;
                case 'IN': return condition.value.includes(value);
                default: return false;
            }
        }
    }
}