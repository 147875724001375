import {cloneDeep, merge} from 'lodash';

const SET_MODAL_PARAMS = 'SET_MODAL_PARAMS'

const mutations = {
    [SET_MODAL_PARAMS](state, baseConfig) {
        Object.keys(baseConfig).forEach((key) => {
            state[key] = baseConfig[key];
        });
    },
};

const state = {
    open: false,
    closeBtn: true,
    type: '', // 'modal' or 'panel-left' or 'panel-right' or 'fullscreen'
    title: '',
    headerBar: null,
    content: [],
    vuetifyParams: {},
    subVuetifyParams: {},
    afterCloseFunc: null,
    // 2nd modal, or modal in modal ... This is quite ugly, and could refactored in a N-modal system, but I doubt we will ever really need more than 2 modals
    open2: false,
    closeBtn2: true,
    type2: '', // 'modal' or 'panel-left' or 'panel-right' or 'fullscreen'
    title2: '',
    headerbar2: null,
    content2: [],
    vuetifyParams2: {},
    subVuetifyParams2: {},
    afterCloseFunc2: null,
};

const getters = {
    getModalParam: state => param => state[param],
};

const actions = {
    async "openModal"({ commit, getters, dispatch, state }, payload) {

        if (!('code' in payload) && !('custom' in payload)) {
            throw new Error('You must provide a code to open a modal, or custom params');
        }

        let modal = null

        //AS--> Use custom params
        if('custom' in payload){
            modal = payload.custom

        //AS--> Use YAML params overload by custom params in payload
        }else{
            modal = getters.getBORouteByCode(payload.code);
            //AS--> Complète les données de modal avec les données de payload
            modal = merge(modal,payload)
        }
           
        //AS--> Without modal, we throw an error
        if (!modal) throw new Error('The modal code "' + payload.code + '" does not exist')

        //AS--> Adjust modal container params
        let title            = modal.params?.title ?? ''
        let type             = modal.params?.type ?? 'modal'
        let closeBtn         = modal.params?.closeBtn == true
        let headerBar        = modal.params?.headerBar ?? null
        let vuetifyParams    = modal.vuetifyParams ?? {}
        let subVuetifyParams = modal.subVuetifyParams ?? {}
        let afterCloseFunc   = modal.afterCloseFunc ?? null

        let content = cloneDeep(modal.content)

        const loaderContent = [
            {
                module: getters.getComponent('loader'),
                params: {
                    class: 'text-center',
                }
            }
        ]
        let mustOpenNext;
        if (state.open == false) {
            commit('SET_MODAL_PARAMS', { 
                open             : true, 
                closeBtn         : closeBtn,
                type             : type,
                title            : title,
                headerBar        : headerBar,
                content          : loaderContent, 
                vuetifyParams    : vuetifyParams,
                subVuetifyParams : subVuetifyParams,
                afterCloseFunc   : afterCloseFunc
            })
            mustOpenNext = 1;
        }
        else {
            commit('SET_MODAL_PARAMS', { 
                open2           : true, 
                closeBtn2       : closeBtn,
                type2           : type,
                title2          : title,
                headerBar2      : headerBar,
                content2        : loaderContent,
                vuetifyParams2  : vuetifyParams,
                afterCloseFunc2 : afterCloseFunc
            })
            mustOpenNext = 2;
        }

        

        if ('_entityId' in payload && '_entityType' in payload) {
            let entityQueryParams = {}
            if ('entityIncludes' in modal) {
                entityQueryParams.includes = [...modal.entityIncludes];
            }

            // @Todo remove this code :
            // Il y a un bug dans setRowContext => on ne copie pas les data json
            // En conséquence il faut débugger setRowContext pour copier aussi le json, puis ensuite supprimer ce code
            // Il faut aussi nettoyer le YAML qui contient des queryParams qui ne sont pas utilisés
            // .... Sauf si on garde, pour avoir des queryParams différents dans les modales par rapport aux data-list ?
            await dispatch('entityType/get', {
                _entityType: payload._entityType,
                _entityId: payload._entityId,
                _params: entityQueryParams,
            });

            if(content && content.length){
                content.forEach((item) => {
                    if ('params' in item) {
                        // Note that giving the _entityType here should be useless : modules that make use of an entityType should already know which type
                        // they are dealing with, and should not need to be told by the modal
                        // I prefer to keep the entityType in the YAML anyway
                        item.params._entityType = payload._entityType;
                        item.params._entityId = payload._entityId;
                    }
                });
            }
        }

        if (payload._context && payload._context == 'row') {
            dispatch('entityType/setRowContext', {
                _entityType: payload._entityType,
                _entityId: payload._entityId,
            });
        }
        
        // Recursively add context=page|row to each content
        const addContextToContent = function(content) {
            if(content && content.length){
                content.forEach((item) => {
                    item.context = payload._context ? payload._context : 'page';
                    if (item.content != undefined) {
                        addContextToContent(item.content);
                    }
                });
            }
        }
        addContextToContent(content)
        if (mustOpenNext == 1) {
            commit('SET_MODAL_PARAMS', { 
                open: true,
                closeBtn: closeBtn,
                type: type,
                title: title,
                headerBar: headerBar,
                content: content,
                vuetifyParams: vuetifyParams,
                subVuetifyParams: subVuetifyParams,
                afterCloseFunc: afterCloseFunc
            })
        }
        else {
            commit('SET_MODAL_PARAMS', { 
                open2: true, 
                closeBtn2: closeBtn,
                type2: type,
                title2: title,
                headerBar2: headerBar,
                content2: content, 
                vuetifyParams2: vuetifyParams,
                subVuetifyParams2: subVuetifyParams,
                afterCloseFunc2: afterCloseFunc
            })
        }

        return
    },
    "closeModal"({ commit, state }, payload = {}) {
        if (state.open2 == true) {
            commit('SET_MODAL_PARAMS', {
                open2: false
            });
            if (payload && 'afterCloseFunc' in payload) {
                if (typeof payload.afterCloseFunc == 'function') {
                    payload.afterCloseFunc();
                    return;
                }
                else {
                    return;
                }
            }
            if (state.afterCloseFunc2) {
                state.afterCloseFunc2();
                return;
            }
        }
        else {
            commit('SET_MODAL_PARAMS', {
                open: false
            });
            if (payload && 'afterCloseFunc' in payload) {
                if (typeof payload.afterCloseFunc == 'function') {
                    payload.afterCloseFunc();
                    return;
                }
                else {
                    return;
                }
            }
            if (state.afterCloseFunc) {
                state.afterCloseFunc();
                return;
            }  
        }
    },
    "closeModal2"({ commit, state }) {
        commit('SET_MODAL_PARAMS', {
            open2: false
        });
    }
};


export default {
    state,
    getters,
    actions,
    mutations
}