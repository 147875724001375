export default {
    props: {
        context: {
            type: String,
            default: undefined,
        }
    },
    methods: {
        getCurrentContext() {
            return this.context;
        },
        getContextEntityType() {
            if (!this.context) {
                throw new Error('Context is not set');
            }
            return this.$store.getters.getContextEntityType(this.context);
        },
        getContextEntity(format) {
            if (!this.context) {
                throw new Error('Context is not set');
            }
            return this.$store.getters.getContextEntity(this.context, format);
        },
        getContextEntityField(field) {
            if (!this.context) {
                throw new Error('Context is not set');
            }
            return this.$store.getters.getContextEntityField(this.context, field);
        },
        getSubContextEntity(key) {
            return this.$store.state.entityType.subContext[key];
        },
        getSubContextEntityField(key, field) {
            return this.$store.getters.getSubContextEntityField(key, field);
        }
    }
}