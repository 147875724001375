import api from '@shared/services/api'
import { get, set, cloneDeep, merge } from "lodash";

const INIT_FORM = 'INIT_FORM'
const SET_FIELD = 'SET_FIELD'
const SET_FORM_OPTIONS = 'SET_FORM_OPTIONS'
const SET_RESPONSE = 'SET_RESPONSE'
const SET_FORM_STATE_VALUE = 'SET_FORM_STATE_VALUE'

const initialState = {
    nextReady: true,
    submitReady: true,
}

const state = {
    forms: {},
    responses: {},
    forms_params: {},
    forms_options: {},
    forms_state: {},
    current_form_code: null
}

const mutations = {
    [INIT_FORM](state, formParams) {
        state.forms[formParams.code] = {}
        state.responses[formParams.code] = null
        state.forms_params[formParams.code] = formParams
        state.forms_options[formParams.code] = {}
        state.forms_state[formParams.code] = initialState
        state.current_form_code = formParams.code
    },
    [SET_FIELD](state, {code, field, value}) {
        let newForms = cloneDeep(state.forms)
        if (field == '_root') {
            merge(newForms[code], value)
            state.forms = newForms
        }
        else {
            set(newForms, `${code}.${field}`, value)
            state.forms = newForms
        }
    },
    [SET_FORM_OPTIONS](state, {formCode, field, options}) {
        state.forms_options[formCode][field] = options
    },
    [SET_RESPONSE](state, {formCode, response}) {
        state.responses[formCode] = response
    },
    [SET_FORM_STATE_VALUE](state, {formCode, key, value}) {
        state.forms_state = set(state.forms_state, `${formCode}.${key}`, value)
    }
}

const getters = {
    // Maybe not useful
    "form/getField": (state) => (code, field) => {
        return get(state.forms[code], field)
    },
    "form/getOptions": (state) => (formCode, field) => {
        return get(state.forms_options[formCode], field)
    }
}


const actions = {
    "form/initForm"({ commit }, formParams) {
        commit(INIT_FORM, formParams)
    },
    "form/setField"({ commit }, {code, field, value}) {
        commit(SET_FIELD, {code, field, value})
    },
    "form/addItemToField"({ commit }, {code, field, value}) {
        let oldValue = get(state.forms[code], field)
        if (!oldValue) {
            oldValue = []
        }
        commit(SET_FIELD, {code, field, value: [...oldValue, value]})
    },
    "form/removeFromField"({ commit }, {code, field, value, idField, idValue}) {
        let oldValue = get(state.forms[code], field)
        if (!oldValue) {
            oldValue = []
        }
        commit(SET_FIELD, {code, field, value: oldValue.filter((item) => item[idField] !== idValue)})
    },
    "form/updateItemInField"({ commit }, {code, field, value, idField, idValue}) {
        let oldValue = get(state.forms[code], field)
        if (!oldValue) {
            oldValue = []
        }
        commit(SET_FIELD, {code, field, value: oldValue.map((item) => item[idField] === idValue ? value : item)})
    },
    "form/setFields"({ commit }, {code, fields}) {
        fields.forEach((field) => {
            commit(SET_FIELD, {code, field: field.field, value: field.value})
        })
    },
    "form/addValidation"({ commit }, {code, field, validations}) {
        commit(SET_FORM_VALIDATION, {formCode: code, field: field, validations: validations})
    },
    "form/setFormStateValue"({ commit }, {formCode, key, value}) {
        commit(SET_FORM_STATE_VALUE, {formCode: formCode, key: key, value: value})
    },
    async "form/fetchOptions"({ commit }, params) {
        let entityType = params._entityType;
        let formCode = params._formCode;
        let field = params._field;
        let queryParams = params._queryParams;
        let getterStruct = params._getterStruct ? params._getterStruct : [['value', 'id'], ['title', 'label']];
            
  
        return new Promise((resolve, reject) => {
          api.jsonApi(entityType, queryParams).then((data) => {
              commit(SET_FORM_OPTIONS, {
                  formCode: formCode,
                  field: field,
                  options: data.model.get(getterStruct)
              });
              resolve('ok');
          }).catch((error) => {
              reject(error);
          })
        })
    },
    async "form/submit"({ commit, dispatch }, formCode) {
        return new Promise((resolve, reject) => {
            // Prepare the data : flatten all fields that start by __flatten__
            let _data = cloneDeep(state.forms[formCode]);
            Object.keys(_data).forEach((key) => {
                if (key.startsWith('__flatten__')) {
                    Object.keys(_data[key]).forEach((subKey) => {
                        if (_data[subKey] != _data[key][subKey]) {
                            _data[subKey] = _data[key][subKey];
                        }
                    });
                    delete _data[key];
                }
            });

            if ('url' in state.forms_params[formCode].submit) {
                api.restPost(state.forms_params[formCode].submit.url, _data).then((data) => {
                    commit(SET_RESPONSE, {formCode: formCode, response: data})
                    resolve(data);
                }).catch((error) => {
                    reject(error);
                })
            }
            if ('entityType' in state.forms_params[formCode].submit) {
                if ('uuid' in _data) {
                    dispatch('entityType/patch', {
                        _entityType: state.forms_params[formCode].submit.entityType,
                        _entityId: _data.uuid,
                        ..._data
                    }).then((data) => {
                        commit(SET_RESPONSE, {formCode: formCode, response: data})
                        resolve(data);
                    }).catch((error) => {
                        reject(error);
                    })
                }
                else {
                    dispatch('entityType/post', {
                        _entityType: state.forms_params[formCode].submit.entityType,
                        ..._data
                    }).then((data) => {
                        commit(SET_RESPONSE, {formCode: formCode, response: data})
                        resolve(data);
                    }).catch((error) => {
                        reject(error);
                    })
                }
            }
            if ('storeAction' in state.forms_params[formCode].submit) {
                dispatch(state.forms_params[formCode].submit.storeAction, {
                    ..._data
                }).then((data) => {
                    commit(SET_RESPONSE, {formCode: formCode, response: data})
                    resolve(data);
                }).catch((error) => {
                    reject(error);
                })
            }
        })
    },
    "form/afterSubmitSuccess"({ commit, state }, formCode) {
        console.log('afterSubmitSuccess', formCode);
        state.forms_state[formCode].afterSubmitSuccess();
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}