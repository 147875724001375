import cloneDeep from "lodash/cloneDeep";

import ChartIcon from "@shared/components/icons/ChartIcon.vue";
import ExitIcon from "@shared/components/icons/ExitIcon.vue";
import GridIcon from "@shared/components/icons/GridIcon.vue";
import MedicalBagIcon from "@shared/components/icons/MedicalBagIcon.vue";
import ParametersIcon from "@shared/components/icons/ParametersIcon.vue";
import UserScanIcon from "@shared/components/icons/UserScanIcon.vue";
import PatientIcon from "@shared/components/icons/PatientIcon.vue";
import OrganizationIcon from "@shared/components/icons/OrganizationIcon.vue";
import conditionsBlock from "@shared/mixins/conditions_block";

export default {
  mixins: [conditionsBlock],
  components: {
    ChartIcon,
    ExitIcon,
    GridIcon,
    MedicalBagIcon,
    ParametersIcon,
    UserScanIcon,
    PatientIcon,
    OrganizationIcon,
  },
  data() {
    return {
      isSubActive: {},
      localDrawer: this.drawer,
    };
  },
  props: {
    drawer: Boolean,
  },
  emits: ["update:drawer"],
  methods: {
    logout() {
      this.$store.dispatch("auth/logout").then(() => {
        this.$router.push("/accounts");
      });
    },
    setIsSubActive(path) {
      // If you need to add sub-routes : routes that should add the "active class" to their "parent" item in the menu,
      // add rules here
      // path will be the "menu" path, and this.$route.fullPath the current full path.
      // Set here rules that returns "true" (and then, add the "active class to the parent) when necessary
      // if (path == '/patients' && this.$route.fullPath.substr(0, 9) == '/patient/') this.isSubActive[path] = true;

      this.isSubActive[path] = false;
    },
    getMenu(position) {
      let self = this;
      return this.$store.getters.getBORoutes.filter(function (m) {
        self.setIsSubActive(m.path);
        let access = true;
        if ("conditionsBlock" in m) {
          if (!self.getConditionsBlockResult(m.conditionsBlock))
            access = false;
        }
        if ("menu" in m && !("position" in m.menu)) {
          m.menu.position = "top";
        }
        return "menu" in m
          && m.menu.position == position
          && access;
      });
    },
    
    debug() {
      console.log("Routing : ", cloneDeep(this.$router.getRoutes()));
      console.log("Routing, matched :", this.$route);
      console.log("Store : ", cloneDeep(this.$store.state));

      //AS--> Active le debug dans le store
      this.$store.dispatch("ui/toggleDebug");
    },
  },
  computed: {
    isLocalhost() {
      return window.location.href.includes("localhost");
    },
    mustShowOrg() {
      return this.$store.state.auth.profile?.organizations?.length > 1;
    },
    currentOrg() {
      return this.$store.state.auth.profile?.organizations?.find(
        (org) => org.id === this.$store.state.auth.profile.current_org_id
      ).label;
    }
  },
  watch: {
    "$route.fullPath"() {
      let self = this;
      Object.keys(this.isSubActive).forEach(function (path) {
        self.setIsSubActive(path);
      });
    },
    // @TODO wtf is this, making store update when cursor is on the menu ???
    drawer(newVal) {
      this.localDrawer = newVal;
    },
    localDrawer(newVal) {
      this.$emit("update:drawer", newVal);
    },
  },
};
