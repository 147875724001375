import ContentTypeElement from "@shared/components/ContentTypeElement.vue";

export default {
  components: {
    ContentTypeElement
  },
  props: {
    content: {
      type: Array,
      default: () => ([]),
    },
  },
  created() {

    
  },
  methods: {

  },
  computed: {
    getContent() {
      let content = [];
      this.content.forEach((element, k) => {
        const kRandomized = k + Math.random(); // Give a randomized param to VueJS to force re-rendering
        content.push({ element, id: kRandomized });
      });

      return content;
    }
  },
};