export const staticPrimaryColor = "#8C57FF";

export const themes = {
  light: {
    dark: false,
    // colors: store.getters.getBOTheme['colors']
    colors: {
      primary: "#6A3DB4", // violet
      "primary-lighten-5": "#E5E4F3", // violet 10%
      "primary-lighten-3": "#D7D1EC", // violet 20%
      "primary-lighten-1": "#AE99D7", /// violet 50%
      secondary: "#6A3DB4", // violet
      background: "#F2F6FA",
      surface: "#FFF",
      "on-surface": "#080232",
      "surface-input": "#F2F6FA",
      "main-dark": "#080232",
      "main-dark-lighten-1": "#7D7C96", // main dark 50%
      "main-dark-lighten-3": "#C2C3D0", // main dark 20%
      "main-dark-lighten-5": "#DBDEE6", // main dark 10%
      bodytext: "#080232", // main dark
      darktext: "#080232", // main dark
      lighttext: "#737087", // main dark 50%
      blue: "#19559B",
      "blue-lighten-5": "CDE0FB",
      error: "#D54242",
      "error-dark": "#9B1919",
      info: "#199B55",
      success: "#22bd15",
      "success-dark": "#199B55",
      "success-dark-ligten-1": "#54CC8B",
      warning: "#ff9900",
      badge: "#DC8686",
      "badge-lighten-1": "rgba(255, 255, 255, 0.50)",
      "primary-grey": "#180D5B",
    },
    variables: {
      "table-header-color": "#737087",
      "high-emphasis-opacity": 0.9,
      "medium-emphasis-opacity": 0.6,
      "low-emphasis-opacity": 0.4,
    },
  },
};
export default themes;
