import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { get, set, cloneDeep } from "lodash";
dayjs.extend(utc)
import store from "@shared/store";
import {useRoute} from "vue-router";


export default {
    methods: {
        first(data) {
            if (!data) return null;
            return data[0];
        },
        getArray(data) {
            if (!data) return [];
            return data;
        },
        getStoreGetterValue(getter, ...args) {
            if (!args) return store.getters[getter];
            return store.getters[getter](...args);
        },
        getStoreValue(key) {
            return get(store.state, key);
        },
        getCurrentFormValue(field) {
            // @todo make this improved version work => get rid of store.state.form.current_form_code
            //
            // if (!this.current_form_code) {
            //     throw new Error('current_form_code is not defined');
            // }
            // return store.getters['form/getField'](this.current_form_code, field);

            const current_form_code = store.state.form.current_form_code;
            return store.getters['form/getField'](current_form_code, field);
        },
        getRouteName() {
            const route = useRoute();
            return route && "name" in route ? route.name : undefined;
        },
        getRouteParam(key) {
            const route = useRoute();
            return route && "params" in route ? route.params[key] : undefined;
        },
        userHasPermission(permission) {
            return store.getters.userHasPermission(permission);
        },
        getDateNow(hours) {
            let now = dayjs().format('YYYY-MM-DD');
            if(hours) now += 'T' + hours;
            return now;
        },
        getDateTimeNow() {
            return dayjs().format('YYYY-MM-DD\THH:mm:ss');
        },
        datePlusOneYear() {
            return dayjs().add(1, 'year').format('YYYY-MM-DD');
        },
        dateMoreDays(days, hours) {
            let day = dayjs().add(days, 'day').format('YYYY-MM-DD');
            if(typeof hours == 'undefined') hours = '23:59:59'
            if(hours) day += ' ' + hours;
            return day;
        },
        dateLessDays(days, hours) {
            let day = dayjs().subtract(days, 'day').format('YYYY-MM-DD');
            if(typeof hours == 'undefined') hours = '00:00:00'
            if(hours) day += 'T' + hours;
            return day;
        },
        formatDate(data, format) {
            if (!data) return '';
            data = dayjs(data).utc('z').local()
            if (data == 'Invalid Date') return '-';
            if(format == 'u'){
                return data.unix()
            }
            return data.format(format)
        },
        getAgeFromDate(data) {
            if (!data) return '';
            return dayjs().diff(data, 'year');
        },
        formatDateFromMicrotime(data, format) {
            if (!data) return '';
            data = Math.round(data / 100000000)
            return this.formatDateFromTimestamp(data, format)
        },
        formatDateFromTimestamp(data, format) {
            if (!data) return '';
            data = dayjs.unix(data).format(format);
            return data;
        },
        join(...data) {
            if (!data) return '';
            if (typeof data === 'string') return data;
            // Take the first param as the limiter
            const limiter = data[0];
            // Take the rest as the data to join
            data = data.slice(1);

            return data.join(limiter);
        },
        joinModel(data, field) {
            if (!data) return '';
            return data.map((item) => {
                return item.get(field);
            }).join(', ');
        },
        count(data) {
            if (!data) return 0;
            return data.length;
        },
        last(data) {
            return data[data.length - 1];
        },
        keys(data) {
            if (!data) return [];
            return Object.keys(data);
        },
        sortNumeric(data, order = 'asc') {
            if (!data) return [];
            if (order == 'asc') {
                return data.sort((a, b) => a - b);
            }
            
            return data.sort((a, b) => b - a);
        },
        filterModel(data, field, value) {
            if (!data) return [];
            let args = {};
            args[field] = value;
            return data.where(args);
        },
        // Find an item in $data, identified by $field == $value, and returm the $key of this item 
        findAndGetKey(data, field, value, key) {
            if (!data) return undefined;
            let item = data.find((item) => {
                return item[field] == value;
            })
            if (!item) return undefined;
            return item[key];
        },
        lastByDate(data, field) {
            if (!data || !Array.isArray(data) || data[0] == undefined) return null;

            data.sort((a, b) => {
                return dayjs(a.get(field)).diff(dayjs(b.get(field)))
            });

            return data[data.length - 1];
        },
        concat(...data) {
            if (!data) return [];
            return data.concat();
        },
        substring(data, start, length) {
            if (!data) return '';
            return data.substring(start, length);
        },
        jsonToList(data){
            if(typeof data == 'string') data = JSON.parse(data);
            let list = '<ul>'
            data = Object.entries(data).map(function([key, value]){
                let show = typeof value == 'object' && value !== null ? this.jsonToList(value) : value
                list += '<li><b>' + key + '</b>: ' + show + '</li>'
            }.bind(this));
            list += '</ul>'
            return list
        },
        formatSSN(ssn) {
            let formattedValue = ssn
            .replace(/\D/g, "")
            .replace(
                /(\d{1})(\d{2})?(\d{2})?(\d{2})?(\d{3})?(\d{3})?(\d{2})/,
                function (_, p1, p2, p3, p4, p5, p6, p7) {
                let parts = [p1, p2, p3, p4, p5, p6, p7].filter(Boolean);
                // The filter(Boolean) is used to remove undefined parts (when the SSN is not fully entered yet).
                return parts.join(" ");
                }
            );
            return formattedValue;
        },
        // map(params) {
        //     const mapperFunc = function(item) {
        //         const parents = item.get(params[0]);
        //         if (parents && 'length' in parents && parents.length > 1) {
        //             return parents.map((parent) => {
        //                 return parent.get(params[1]);
        //             }).sort().join(', ');
        //         }
        //         else if (parents) {
        //             return parents.get(params[1]);
        //         }
        //         else {
        //             return null
        //         }
        //     }

        //     return mapperFunc;
        // },
        // Copy-pasted from BHW, I keep it here for reference, but it should not be used as is
        //
        // format(params) {
        //     return function(item) {

        //         let format_data = function(data, code) {
        //             if (data == null) return '';
        //             let format = code.split("|");
        
        //             if (format[0] == "date") {
        //                 data = dayjs(data).utc('z').local().format(format[1]);
        //                 return data;
        //             }
        
        //             if (format[0] == "dateTT") {
        //                 data = dayjs.unix(data).format(format[1]);
        //                 return data;
        //             }
        
        //             if (format[0] == "number") {
        //                 let fixed = format[1] != undefined ? format[1] : 0;
        //                 data = parseFloat(data).toFixed(fixed);
        //             }
        
        //             if (format[0] == "bigNumber") {
        //                 if (data == '-') {
        //                     return data;
        //                 }
        //                 let fixed = format[1] != undefined ? format[1] : 0;
        //                 data = parseFloat(data).toLocaleString(fixed);
        //             }
        
        //             if (data == 'NaN') {
        //                 return null;
        //             }
                    
        //             if (data % 1 == 0) {
        //                 return parseInt(data)
        //             }
                    
        //             return data;
        //         }


        //         return format_data(item.get(params[0]), params[1] + '|' + params[2]); // Ugly format, legacy of BHW on the format_data mixins ... Should be refact.
        //     }
        // }
    }
}