import { defineAsyncComponent } from "vue";

export default {
  import: (app) => {
    app.component(
      "loader",
      defineAsyncComponent(() => import("@shared/components/Loader.vue"))
    );

    // Basic types, not supposed to be modules
    app.component(
      "table-list",
      defineAsyncComponent(() =>
        import("@shared/components/data-list/TableList.vue")
      )
    );
    app.component(
      "cards-list",
      defineAsyncComponent(() =>
        import("@shared/components/data-list/CardsList.vue")
      )
    );
    app.component(
      "carrousel-list",
      defineAsyncComponent(() =>
        import("@shared/components/data-list/CarrouselList.vue")
      )
    );
    app.component(
      "slick-list",
      defineAsyncComponent(() =>
        import("@shared/components/data-list/SlickList.vue")
      )
    );
    app.component(
      "items-list",
      defineAsyncComponent(() =>
        import("@shared/components/data-list/ItemsList.vue")
      )
    );
    app.component(
      "generic-list",
      defineAsyncComponent(() =>
        import("@shared/components/data-list/GenericList.vue")
      )
    );

    // app.component('modal', defineAsyncComponent(() => import('@shared/views/Modal.vue'))); <= to be deleted

    // Shared modules
    // Auth
    app.component(
      "auth.layout",
      defineAsyncComponent(() => import("@shared/components/auth/Layout.vue"))
    );
    app.component(
      "auth.welcome",
      defineAsyncComponent(() => import("@shared/components/auth/Welcome.vue"))
    );
    app.component(
      "auth.accounts",
      defineAsyncComponent(() => import("@shared/components/auth/Accounts.vue"))
    );
    app.component(
      "auth.signin",
      defineAsyncComponent(() => import("@shared/components/auth/Signin.vue"))
    );
    app.component(
      "auth.pre-signup",
      defineAsyncComponent(() =>
        import("@shared/components/auth/PreSignup.vue")
      )
    );
    app.component(
      "auth.pre-signup.screens",
      defineAsyncComponent(() =>
        import("@shared/components/auth/PreSignupScreens.vue")
      )
    );
    app.component(
      "auth.lookup-patient",
      defineAsyncComponent(() =>
        import("@shared/components/auth/LookupPatient.vue")
      )
    );
    app.component(
      "auth.lookup-staff",
      defineAsyncComponent(() =>
        import("@shared/components/auth/LookupStaff.vue")
      )
    );
    app.component(
      "auth.signup",
      defineAsyncComponent(() => import("@shared/components/auth/Signup.vue"))
    );
    app.component(
      "auth.reset-password",
      defineAsyncComponent(() =>
        import("@shared/components/auth/ResetPassword.vue")
      )
    );
    app.component(
      "auth.force-change-password",
      defineAsyncComponent(() =>
        import("@shared/components/auth/ForceChangePassword.vue")
      )
    );
    app.component(
      "auth.password-form-default",
      defineAsyncComponent(() =>
        import("@shared/components/auth/PasswordFormDefault.vue")
      )
    );
    app.component(
      "auth.password-form-pincode",
      defineAsyncComponent(() =>
        import("@shared/components/auth/PasswordFormPincode.vue")
      )
    );
    app.component(
      "auth.no-access",
      defineAsyncComponent(() => import("@shared/components/auth/NoAccess.vue"))
    );
    app.component(
      "auth.biometry-setup",
      defineAsyncComponent(() =>
        import("@shared/components/auth/BiometrySetup.vue")
      )
    );
    app.component(
      "auth.select-org",
      defineAsyncComponent(() =>
        import("@shared/components/auth/SelectOrg.vue")
      )
    );

    // Addresses
    app.component(
      "address-form",
      defineAsyncComponent(() =>
        import("@shared/components/address/AddressForm.vue")
      )
    );
    app.component(
      "address-widget",
      defineAsyncComponent(() =>
        import("@shared/components/address/AddressWidget.vue")
      )
    );
    app.component(
      "address-in-dashboard",
      defineAsyncComponent(() =>
        import("@shared/components/address/AddressInDashboard.vue")
      )
    );

    // Settings
    app.component(
      "settings.index",
      defineAsyncComponent(() =>
        import("@shared/components/settings/Index.vue")
      )
    );
    app.component(
      "settings.me",
      defineAsyncComponent(() => import("@shared/components/settings/Me.vue"))
    );
    app.component(
      "settings.documents",
      defineAsyncComponent(() =>
        import("@shared/components/settings/Documents.vue")
      )
    );
    app.component(
      "documents.document",
      defineAsyncComponent(() =>
        import("@shared/components/documents/Document.vue")
      )
    );
    app.component(
      "documents.add-document",
      defineAsyncComponent(() =>
        import("@shared/components/documents/AddDocument.vue")
      )
    ),
      app.component(
        "settings.notifications",
        defineAsyncComponent(() =>
          import("@shared/components/settings/Notifications.vue")
        )
      );
    app.component(
      "settings.accept_eula",
      defineAsyncComponent(() =>
        import("@shared/components/settings/AcceptEula.vue")
      )
    );
    app.component(
      "settings.cgu",
      defineAsyncComponent(() => import("@shared/components/settings/CGU.vue"))
    );
    app.component(
      "settings.customer-cgu",
      defineAsyncComponent(() =>
        import("@shared/components/settings/CustomerCGU.vue")
      )
    );
    app.component(
      "settings.reset-user",
      defineAsyncComponent(() =>
        import("@shared/components/settings/ResetUser.vue")
      )
    );

    // Roles
    app.component(
      "user.roles",
      defineAsyncComponent(() => import("@shared/components/user/Roles.vue"))
    );
    app.component(
      "user.add-edit-form",
      defineAsyncComponent(() => import("@shared/components/user/AddEditForm.vue"))
    )

    // Generic modules
    app.component(
      "separator",
      defineAsyncComponent(() => import("@shared/components/containers/Separator.vue"))
    )

    app.component(
      "container",
      defineAsyncComponent(() =>
        import("@shared/components/containers/Container.vue")
      )
    );
    app.component(
      "container.sheet",
      defineAsyncComponent(() =>
        import("@shared/components/containers/Sheet.vue")
      )
    );
    app.component(
      "container.row",
      defineAsyncComponent(() =>
        import("@shared/components/containers/Row.vue")
      )
    );
    app.component(
      "container.tabs",
      defineAsyncComponent(() =>
        import("@shared/components/containers/Tabs.vue")
      )
    );
    app.component(
      "container.router-view-tabs",
      defineAsyncComponent(() =>
        import("@shared/components/containers/RouterViewTabs.vue")
      )
    );
    app.component(
      "container.pager",
      defineAsyncComponent(() =>
        import("@shared/components/containers/Pager.vue")
      )
    )

    app.component(
      "btn",
      defineAsyncComponent(() =>
        import("@shared/components/types/Btn.vue")
      )
    );
    app.component(
      "link",
      defineAsyncComponent(() =>
        import("@shared/components/types/Link.vue")
      )
    );
    app.component(
      "wip_block",
      defineAsyncComponent(() =>
        import("@shared/components/containers/WipBlock.vue")
      )
    );

    app.component(
      "header.back_button",
      defineAsyncComponent(() =>
        import("@shared/components/header/BackButton.vue")
      )
    );
    app.component(
      "header.steps",
      defineAsyncComponent(() => import("@shared/components/header/Steps.vue"))
    );

    app.component(
      "title",
      defineAsyncComponent(() => import("@shared/components/types/Title.vue"))
    );
    app.component(
      "alert",
      defineAsyncComponent(() => import("@shared/components/types/Alert.vue"))
    );

    app.component(
      "data-list",
      defineAsyncComponent(() =>
        import("@shared/components/data-list/List.vue")
      )
    );
    app.component(
      "rest-form",
      defineAsyncComponent(() => import("@shared/components/form/RestForm.vue"))
    );


    app.component(
      "form",
      defineAsyncComponent(() => import("@shared/components/form/Form.vue"))
    );
    app.component(
      "form-page",
      defineAsyncComponent(() => import("@shared/components/form/FormPage.vue"))
    );

    app.component(
      "form-field.text",
      defineAsyncComponent(() => import("@shared/components/form/fields/Text.vue"))
    )
    app.component(
      "form-field.select",
      defineAsyncComponent(() => import("@shared/components/form/fields/Select.vue"))
    )
    app.component(
      "form-field.radio",
      defineAsyncComponent(() => import("@shared/components/form/fields/Radio.vue"))
    )    
    app.component(
      "form-field.checkbox",
      defineAsyncComponent(() => import("@shared/components/form/fields/Checkbox.vue"))
    )
    app.component(
      "form-field.radio-boolean",
      defineAsyncComponent(() => import("@shared/components/form/fields/RadioBoolean.vue"))
    )
    app.component(
      "form-field.checkbox-boolean",
      defineAsyncComponent(() => import("@shared/components/form/fields/CheckboxBoolean.vue"))
    )
    app.component(
      "form-field.checkbox-subchoices",
      defineAsyncComponent(() => import("@shared/components/form/fields/CheckboxSubchoices.vue"))
    )
    app.component(
      "form-field.radio-subchoices",
      defineAsyncComponent(() => import("@shared/components/form/fields/RadioSubchoices.vue"))
    )
    app.component(
      "form-field.radio-boolean-subchoices",
      defineAsyncComponent(() => import("@shared/components/form/fields/RadioBooleanSubchoices.vue"))
    )
    app.component(
      "form-field.file",
      defineAsyncComponent(() => import("@shared/components/form/fields/File.vue"))
    )
    app.component(
      "form-field.file-preview",
      defineAsyncComponent(() => import("@shared/components/form/fields/FilePreview.vue"))
    )
    app.component(
      "form-field.date",
      defineAsyncComponent(() => import("@shared/components/form/fields/Date.vue"))
    )
    app.component(
      "form-field.phone",
      defineAsyncComponent(() => import("@shared/components/form/fields/Phone.vue"))
    )
    app.component(
      "form-field.address",
      defineAsyncComponent(() => import("@shared/components/form/fields/Address.vue"))
    )




    app.component(
      "data-block",
      defineAsyncComponent(() =>
        import("@shared/components/types/DataBlock.vue")
      )
    );
    app.component(
      "multi-data-block",
      defineAsyncComponent(() =>
        import("@shared/components/types/MultiDataBlock.vue")
      )
    );

    app.component(
      "alert-modal",
      defineAsyncComponent(() =>
        import("@shared/components/types/AlertModal.vue")
      )
    );

    app.component(
      "capacitor.camera",
      defineAsyncComponent(() =>
        import("@shared/components/capacitor/Camera.vue")
      )
    );

    app.component(
      "entities.simple-yes-no",
      defineAsyncComponent(() =>
        import("@shared/components/entities/SimpleYesNo.vue")
      )
    );

    // Notifications
    app.component(
      "notifications.alert_list",
      defineAsyncComponent(() =>
        import("@shared/components/notifications/AlertList.vue")
      )
    );
    app.component(
      "notifications.alert_modals",
      defineAsyncComponent(() =>
        import("@shared/components/notifications/AlertModals.js")
      )
    );
    // ActiveQueue
    app.component(
      "active-queue.patient-block",
      defineAsyncComponent(() =>
        import("@shared/components/active_queue/PatientBlock.vue")
      )
    );

    // Encounter
    app.component(
      "encounter",
      defineAsyncComponent(() =>
        import("@shared/components/encounter/Encounter.vue")
      )
    );
    app.component(
      "observation.observations-definition-field",
      defineAsyncComponent(() =>
        import("@shared/components/encounter/ObservationDefinitionField.vue")
      )
    );

    app.component(
      "encounter.summary",
      defineAsyncComponent(() =>
        import("@shared/components/encounter/Summary.vue")
      )
    );

    // Catalog
    app.component(
      "catalog.product",
      defineAsyncComponent(() =>
        import("@shared/components/catalog/Product.vue")
      )
    );
    app.component(
      "catalog.product_card",
      defineAsyncComponent(() =>
        import("@shared/components/catalog/ProductCard.vue")
      )
    );
    app.component(
      "catalog.device_category",
      defineAsyncComponent(() =>
        import("@shared/components/catalog/DeviceCategory.vue")
      )
    );
    app.component(
      "catalog.device_type_list.fullcard",
      defineAsyncComponent(() =>
        import("@shared/components/catalog/DeviceTypeListFullcard.vue")
      )
    );
    app.component(
      "catalog.device_type_list.caroussel",
      defineAsyncComponent(() =>
        import("@shared/components/catalog/DeviceTypeListCaroussel.vue")
      )
    );

    // Patients
    app.component(
      "patient.patient_card",
      defineAsyncComponent(() => import("@shared/components/patient/Card.vue"))
    );

    app.component(
      "patient.episodes-of-care",
      defineAsyncComponent(() =>
        import("@shared/components/patient/EpisodesOfCare.vue")
      )
    );
    app.component(
      "patient.search_patient",
      defineAsyncComponent(() => import("@shared/components/patient/SearchPatient.vue"))
    )
    app.component(
      "patient.right-holder-in-dashboard",
      defineAsyncComponent(() =>
        import("@shared/components/patient/RightHolderInDashboard.vue")
      )
    );


    // Orders
    app.component(
      "order",
      defineAsyncComponent(() => import("@shared/components/order/Order.vue"))
    );
    app.component(
      "order.start_button",
      defineAsyncComponent(() =>
        import("@shared/components/order/StartButton.vue")
      )
    );
    app.component(
      "order.product_card",
      defineAsyncComponent(() =>
        import("@shared/components/order/ProductCard.vue")
      )
    );
    app.component(
      "order.product_card_variation",
      defineAsyncComponent(() =>
        import("@shared/components/order/ProductCardVariation.vue")
      )
    );
    app.component(
      "order.product_card_cart",
      defineAsyncComponent(() =>
        import("@shared/components/order/ProductCardCart.vue")
      )
    );
    app.component(
      "order.product_card_cart_variation",
      defineAsyncComponent(() =>
        import("@shared/components/order/ProductCardCartVariation.vue")
      )
    );
    app.component(
      "order.back_button",
      defineAsyncComponent(() =>
        import("@shared/components/order/BackButton.vue")
      )
    );
    app.component(
      "order.next_button",
      defineAsyncComponent(() =>
        import("@shared/components/order/NextButton.vue")
      )
    );
    app.component(
      "order.floating_cart_button",
      defineAsyncComponent(() =>
        import("@shared/components/order/FloatingCartButton.vue")
      )
    );
    app.component(
      "order.cart",
      defineAsyncComponent(() => import("@shared/components/order/Cart.vue"))
    );
    app.component(
      "order.select_target",
      defineAsyncComponent(() =>
        import("@shared/components/order/SelectTarget.vue")
      )
    );
    app.component(
      "order.select_type",
      defineAsyncComponent(() =>
        import("@shared/components/order/SelectType.vue")
      )
    );
    app.component(
      "order.select_address",
      defineAsyncComponent(() =>
        import("@shared/components/order/SelectAddress.vue")
      )
    );
    app.component(
      "order.summary",
      defineAsyncComponent(() => import("@shared/components/order/Summary.vue"))
    );
    app.component(
      "order.cart_slot",
      defineAsyncComponent(() =>
        import("@shared/components/order/CartSlot.vue")
      )
    );

    app.component(
      "order.order_card",
      defineAsyncComponent(() =>
        import("@shared/components/order/OrderCard.vue")
      )
    );
    app.component(
      "order.empty_list",
      defineAsyncComponent(() =>
        import("@shared/components/order/EmptyList.vue")
      )
    );

    app.component(
      "order.product_modal",
      defineAsyncComponent(() =>
        import("@shared/components/order/ProductModal.vue")
      )
    );

    app.component(
      "order.ordered_product_variation_item",
      defineAsyncComponent(() =>
        import("@shared/components/order/OrderedProductVariationItem.vue")
      )
    );

    app.component(
      "order.address_display",
      defineAsyncComponent(() =>
        import("@shared/components/order/AddressDisplay.vue")
      )
    );

    app.component(
      "order.details",
      defineAsyncComponent(() => import("@shared/components/order/Details.vue"))
    );

    // Observations
    app.component(
      "observation.data-block",
      defineAsyncComponent(() =>
        import("@shared/components/observation/DataBlock.vue")
      )
    );
    app.component(
      "observation.chart",
      defineAsyncComponent(() =>
        import("@shared/components/observation/Chart.vue")
      )
    );

    // Devices
    app.component(
      "device.add-to-encounter",
      defineAsyncComponent(() =>
        import("@shared/components/device/AddToEncounter.vue")
      )
    );
    app.component(
      "device.device_list",
      defineAsyncComponent(() =>
        import("@shared/components/device/DeviceList.vue")
      )
    );
    app.component(
      "device.product_card",
      defineAsyncComponent(() =>
        import("@shared/components/device/ProductCard.vue")
      )
    );
    app.component(
      "device.product_item",
      defineAsyncComponent(() =>
        import("@shared/components/device/ProductItem.vue")
      )
    );
    app.component(
      "device.product_video",
      defineAsyncComponent(() =>
        import("@shared/components/device/ProductVideo.vue")
      )
    );
    app.component(
      "device.no_device",
      defineAsyncComponent(() =>
        import("@shared/components/device/NoDevice.vue")
      )
    );

    // Help center
    app.component(
      "help.header",
      defineAsyncComponent(() => import("@shared/components/help/Header.vue"))
    );
    app.component(
      "help.index",
      defineAsyncComponent(() => import("@shared/components/help/Index.vue"))
    );
    app.component(
      "help.article",
      defineAsyncComponent(() => import("@shared/components/help/Article.vue"))
    );
    app.component(
      "help.sav-patient-card",
      defineAsyncComponent(() =>
        import("@shared/components/help/SavPatientCard.vue")
      )
    );

    // Slots (data-list)
    app.component(
      "data-list.slot.status",
      defineAsyncComponent(() =>
        import("@shared/components/data-list/slots/Status.vue")
      )
    );
    app.component(
      "data-list.slot.birthdate-and-age",
      defineAsyncComponent(() =>
        import("@shared/components/data-list/slots/BirthdateAndAge.vue")
      )
    );
    app.component(
      "data-list.slot.mapped-chips",
      defineAsyncComponent(() =>
        import("@shared/components/data-list/slots/MappedChips.vue")
      )
    );
    app.component(
      "data-list.slot.ssn",
      defineAsyncComponent(() =>
        import("@shared/components/data-list/slots/SSN.vue")
      )
    ); 
    app.component(
      "data-list.slot.address",
      defineAsyncComponent(() =>
        import("@shared/components/data-list/slots/Address.vue")
      )
    );            
    app.component(
      "data-list.slot.multi-organizations",
      defineAsyncComponent(() =>
        import("@shared/components/data-list/slots/MultiOrganizations.vue")
      )
    );
    app.component(
      "data-list.slot.mono-organization",
      defineAsyncComponent(() =>
        import("@shared/components/data-list/slots/MonoOrganization.vue")
      )
    );
    app.component(
      "data-list.slot.order-state",
      defineAsyncComponent(() =>
        import("@shared/components/data-list/slots/OrderState.vue")
      )
    );
    app.component(
      "data-list.slot.img",
      defineAsyncComponent(() =>
        import("@shared/components/data-list/slots/Img.vue")
      )
    );
    app.component(
      "data-list.slot.first",
      defineAsyncComponent(() =>
        import("@shared/components/data-list/slots/First.vue")
      )
    );
    app.component(
      "data-list.slot.with-link",
      defineAsyncComponent(() =>
        import("@shared/components/data-list/slots/WithLink.vue")
      )
    );
    app.component(
      "data-list.slot.active-queue-type",
      defineAsyncComponent(() =>
        import("@shared/components/data-list/slots/ActiveQueueType.vue")
      )
    );
    app.component(
      "data-list.slot.active-queue",
      defineAsyncComponent(() =>
        import("@shared/components/data-list/slots/ActiveQueue.vue")
      )
    );
    app.component(
      "data-list.slot.signup-data",
      defineAsyncComponent(() =>
        import("@shared/components/data-list/slots/SignupData.vue")
      )
    );
    app.component(
      "data-list.slot.html",
      defineAsyncComponent(() =>
        import("@shared/components/data-list/slots/Html.vue")
      )
    );
    app.component(
      "data-list.slot.json",
      defineAsyncComponent(() =>
        import("@shared/components/data-list/slots/Json.vue")
      )
    );


    app.component(
      "document.edit-admin",
      defineAsyncComponent(() =>
        import("@shared/components/document/EditAdmin.vue")
      )
    );


    // Help center
    app.component(
      "help.index",
      defineAsyncComponent(() => import("@shared/components/help/Index.vue"))
    );

    // Slots (data-list)
    app.component(
      "data-list.slot.status",
      defineAsyncComponent(() =>
        import("@shared/components/data-list/slots/Status.vue")
      )
    );
    app.component(
      "data-list.slot.chip",
      defineAsyncComponent(() =>
        import("@shared/components/data-list/slots/Chip.vue")
      )
    );
    app.component(
      "data-list.slot.img",
      defineAsyncComponent(() =>
        import("@shared/components/data-list/slots/Img.vue")
      )
    );
    app.component(
      "data-list.slot.with-link",
      defineAsyncComponent(() =>
        import("@shared/components/data-list/slots/WithLink.vue")
      )
    );

    app.component(
      "questionnaire",
      defineAsyncComponent(() =>
        import("@shared/components/questionnaire/questionnaire.vue")
      )
    );
    app.component(
      "questions._base",
      defineAsyncComponent(() =>
        import("@shared/components/questionnaire/questions/_base.vue")
      )
    );
    app.component(
      "questions.section",
      defineAsyncComponent(() =>
        import("@shared/components/questionnaire/questions/section.vue")
      )
    );
    app.component(
      "questions.text",
      defineAsyncComponent(() =>
        import("@shared/components/questionnaire/questions/text.vue")
      )
    );
    app.component(
      "questions.animation",
      defineAsyncComponent(() =>
        import("@shared/components/questionnaire/questions/animation.vue")
      )
    );
    app.component(
      "questions.button",
      defineAsyncComponent(() =>
        import("@shared/components/questionnaire/questions/button.vue")
      )
    );
    app.component(
      "questions.stars",
      defineAsyncComponent(() =>
        import("@shared/components/questionnaire/questions/stars.vue")
      )
    );
    app.component(
      "questions.string",
      defineAsyncComponent(() =>
        import("@shared/components/questionnaire/questions/string.vue")
      )
    );
    app.component(
      "questions.longstring",
      defineAsyncComponent(() =>
        import("@shared/components/questionnaire/questions/longstring.vue")
      )
    );
    app.component(
      "pincode-input",
      defineAsyncComponent(() =>
        import("@shared/components/auth/PincodeInput.vue")
      )
    );
    app.component(
      "questions.device_association",
      defineAsyncComponent(() =>
        import(
          "@shared/components/questionnaire/questions/device_association.vue"
        )
      )
    );
    app.component(
      "questions.product_variation",
      defineAsyncComponent(() =>
        import(
          "@shared/components/questionnaire/questions/product_variation.vue"
        )
      )
    );
    // Appointments
    app.component(
      "appointment.add-appointment",
      defineAsyncComponent(() =>
        import("@shared/components/appointment/AddAppointment.vue")
      )
    );

    // Stats
    app.component(
      "stats.key-metric",
      defineAsyncComponent(() =>
        import("@shared/components/stats/KeyMetric.vue")
      )
    );
    app.component(
      "stats.graph-columns",
      defineAsyncComponent(() =>
        import("@shared/components/stats/GraphColumns.vue")
      )
    );

    // Fields
    app.component(
      "fields.tiptap",
      defineAsyncComponent(() =>
        import("@shared/components/fields/TipTap.vue")
      )
    );

    // Patient
    app.component(
      "form-field.patient.edit-right-holder",
      defineAsyncComponent(() => import("@shared/components/form/fields/patient/EditRightHolder.vue"))
    )
    app.component(
      "form-field.patient.select-right-holder",
      defineAsyncComponent(() => import("@shared/components/form/fields/patient/SelectRightHolder.vue"))
    )    

    app.component(
      'dep.sign-dep', 
      defineAsyncComponent(() => import('@shared/components/dep/SignDep.vue'))
    )

    // DEP
    app.component(
      'form-field.document.health-mutual-reader', 
      defineAsyncComponent(() => import('@shared/components/form/fields/document/HealthMutualReader.vue'))
    )
    app.component(
      "form-field.dep.devices",
      defineAsyncComponent(() => import("@shared/components/form/fields/dep/Devices.vue"))
    )
    app.component(
      "form-field.dep.blood-gas",
      defineAsyncComponent(() => import("@shared/components/form/fields/dep/BloodGas.vue"))
    )
    app.component(
      "form-field.support-request.select-prescriber",
      defineAsyncComponent(() => import("@shared/components/form/fields/support_request/SelectPrescriber.vue"))
    )
    app.component(
      "form-field.support-request.show-pharmacy-info",
      defineAsyncComponent(() => import("@shared/components/form/fields/support_request/ShowPharmacyInfo.vue"))
    )
    app.component(
      "form-field.support-request.summary-pharmacy",
      defineAsyncComponent(() => import("@shared/components/form/fields/support_request/SummaryPharmacy.vue"))
    )
    app.component(
      "form-field.dep.summary-prescriber",
      defineAsyncComponent(() => import("@shared/components/form/fields/dep/SummaryPrescriber.vue"))
    )
    app.component(
      "form-field.dep.respiratory-diagnosis",
      defineAsyncComponent(() => import("@shared/components/form/fields/dep/RespiratoryDiagnosis.vue"))
    )
    app.component(
      "docusign.viewer",
      defineAsyncComponent(() => import("@shared/components/docusign/Viewer.vue"))
    )
    app.component(
      "docusign.embedded-signing",
      defineAsyncComponent(() => import("@shared/components/docusign/EmbeddedSigning.vue"))
    )



    return app;
  },
};
