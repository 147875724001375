const INIT = 'INIT'
const NEXT_PAGE = 'NEXT_PAGE'
const PREV_PAGE = 'PREV_PAGE'

const state = {
    content: [],
    page: 0,
}

const mutations = {
    [INIT](state, content) {
        state.page = 0
        state.content = content
    },
    [NEXT_PAGE](state) {
        state.page++
    },
    [PREV_PAGE](state) {
        state.page--
    }
}

const getters = {
    "pager/getTitles"(state) {
        let titles = [];
        if (!state.content) return titles;
        state.content.forEach((item) => {
            if (item.params?.pageCountInSteps) {
                titles.push(item.params.title)
            }
        })
        return titles;
    },
    "pager/getCurrentTitleNumber"(state, getters) {
        let page = state.page
        let toSubstract = 0;
        state.content.forEach((item, index) => {
            if (index <= page && !item.params.pageCountInSteps) {
                toSubstract++
            }
        })
        return page - toSubstract
    },
    "pager/getCurrentContent"(state) {
        return state.content[state.page]
    }
}


const actions = {
    "pager/nextPage"({ commit }) {
        commit(NEXT_PAGE)
    },
    "pager/prevPage"({ commit }) {
        commit(PREV_PAGE)
    },
    "pager/init"({ commit }, content) {
        commit(INIT, content)
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}