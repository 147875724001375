import { createStore } from 'vuex'
import app from '@shared/store/modules/app'
import ui from '@shared/store/modules/ui'
import auth from '@shared/store/modules/auth'
import dataList from '@shared/store/modules/data_list'
import entityType from '@shared/store/modules/entity_type'
import formOption from '@shared/store/modules/form_option'
import modal from '@shared/store/modules/modal'
import signupRequest from '@shared/store/modules/signup_request'
import role from '@shared/store/modules/role'
import questionnaire from '@shared/store/modules/questionnaire'

import active_queue from '@shared/store/modules/active_queue'
// import encounter from '@shared/store/modules/encounter'
import observation_definition from './modules/observation_definition'
import order from '@shared/store/modules/order'
import pager from '@shared/store/modules/pager'
import form from '@shared/store/modules/form'

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    app,
    auth,
    ui,
    modal,
    dataList,
    entityType,
    formOption,
    signupRequest,
    role,
    questionnaire,
    active_queue,
    // encounter,
    observation_definition,
    order,
    pager,
    form
  },
  strict: false,
})
