import { defineAsyncComponent } from "vue";

export default {
  import: (app) => {
    app.component(
      "auth.layout",
      defineAsyncComponent(() => import("@admin/components/auth/Layout.vue"))
    );
    return app;
  },
};
